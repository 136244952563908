import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { Badge, GamificationService } from '../../services/gamification/gamification.service';
import { TranslateModule } from '../../translate/translate.module';
import { TranslatePipe } from '../../translate/pipes/translate.pipe';
import { BadgeName, EarnedBadge } from '../../models/gamification/EarnedBadges.model';
import { FBaseService, collection } from '../../services/firebase/f-base.service';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-earned-badge-modal',
  templateUrl: './earned-badge-modal.page.html',
  styleUrls: ['./earned-badge-modal.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class EarnedBadgeModalPage implements OnInit {
  @Input() collectedBadgesInBlock: Badge[] = [];
  @Input() badgesFromFirebase: EarnedBadge[] = [];
  modalId: string = 'badge-modal';
  modal: HTMLIonModalElement;
  popStateListener: any;
  swiperInstance: any;
  constructor(
    private platform: Platform,
    private fBaseService: FBaseService,
    private logger: LoggerService
  ) { }

  ngOnInit() { }

  ionViewWillEnter() { }

  ionViewDidEnter() {
    this.getModalInstance();
  }

  getModalInstance() {
    const modal = document.getElementById(this.modalId) as HTMLIonModalElement;
    if (modal) {
      console.log('Badge modal shows,', this.collectedBadgesInBlock);
      this.logger.addLogItem('BADGE_MODAL_SHOWS', this.collectedBadgesInBlock);
      this.modal = modal;
      this.closeModalOnBackButtonNavigation();
      modal.onDidDismiss().then(() => {
        this.ionModalOnDismiss();
      });
    }
  }

  ionModalOnDismiss() {
    if (!this.platform.is('cordova')) {
      if (this.popStateListener) {
        window.removeEventListener('popstate', this.popStateListener);
        this.popStateListener = null;
      }
    }
  }

  closeModalOnBackButtonNavigation() {
    if (!this.platform.is('cordova')) {
      history.pushState(null, '', location.href);
      this.popStateListener = async () => {
        this.modal?.dismiss();
      }
    };
    window.addEventListener('popstate', this.popStateListener);
  }

  async updateSwiperInstance(swiper?: any) {
    const swiperInstance = swiper?.srcElement?.swiper;
    if (swiperInstance) {
      this.swiperInstance = swiperInstance;
      setTimeout(async () => {
        const viewedSlide = this.swiperInstance?.slides[this.swiperInstance?.realIndex];
        if (viewedSlide && viewedSlide?.id) {
          await this.updateBadge(viewedSlide.id, 'REGULAR');
          await this.updateBadge(viewedSlide.id, 'REVENANT');
          await this.updateBadge(viewedSlide.id, 'CENTENARY');
        }
      }, 200);
    }
  }

  async updateBadge(slideId: BadgeName, badgeToUpdate: BadgeName) {
    if (slideId === badgeToUpdate) {
      const badge = this.badgesFromFirebase.find(f => f.name === badgeToUpdate);
      if (badge && badge?.present === true) {
        try {
          badge.present = false;
          await this.fBaseService.saveOrUpdateData(collection.earnedBadges, badge);
        }
        catch (error) {
          this.logger.handleError(error);
          console.log(error);
        }
      }
    }
  }

  swiperInit(e: any) {
    setTimeout(() => {
      const swiperInstance = e?.srcElement?.swiper;
      if (swiperInstance) {
        this.swiperInstance = swiperInstance;
        this.updateSwiperInstance(e);
      }
    }, 0);
  }

  swipe(direction: 'left' | 'right') {
    const swiperInstance = this.swiperInstance;
    if (swiperInstance) {
      if (direction === 'right') {
        swiperInstance.slideNext();
      } else if (direction === 'left') {
        swiperInstance.slidePrev();
      }
    }
  }

}
