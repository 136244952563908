import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VersionService } from '../services/version.service';
import { Platform } from '@ionic/angular';
import { take, map, filter } from 'rxjs/operators';

export function VersionGuard(): Observable<boolean> | Promise<boolean> | boolean {
  const versionService = inject(VersionService);
  const router = inject(Router);
  const platform = inject(Platform);

  const isRunningOnMobileDevice = (): boolean => {
    return platform.is('cordova');
  };

  if (!isRunningOnMobileDevice()) {
    return true;
  }

  return versionService.versionIsValid$.pipe(
    filter(isValid => isValid !== null),
    take(1), // Csak az első értéket vesszük
    map(isValid => {
      if (!isValid) {
        // Ha a verzió érvénytelen
        versionService.showVersionToast(); // Megjelenítjük a toast-ot
        router.navigate(['/login']); // Átirányítjuk a felhasználót
        return false;
      }
      return true; // Ha érvényes a verzió, engedjük a belépést
    })
  );
}
