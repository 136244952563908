import { Metadata } from "../../../../../../models/Metadata";

export interface EarnedBadge {
    id: string;
    name: BadgeName;
    count: number; // hányszor szerezte meg eddig a user az adott badge-et, default 0
    metadata: Metadata;
    badgeSpecificData: BadgeSpecificData; // különböző badge-ekkel való haladást különbözőképpen tudjuk követni, ehhez tartozó adattagokat gyűjtünk ide
    present: boolean; // kell-e értesíteni a felhasználót, hogy megszerezte ezt a badge-et. Default false.
}


export type BadgeSpecificData =
    | PersistentElephantBadgeData
    | PerfectPerformanceBadgeData
    | IndependentElephantBadgeData
    | FirstStepsBadgeData
    | PillarOfConfidenceBadgeData
    | QuizEaterBadgeData
    | MilestoneBadgeData
    | RegularBadgeData
    | RevenantBadgeData
    | CentenaryBadgeData;

export type BadgeName =
    'PERSISTENT_ELEPHANT' | 'PERFECT_PERFORMANCE' | 'INDEPENDENT_ELEPHANT' | 'FIRST_STEPS' |
    'PILLAR_OF_CONFIDENCE' | 'QUIZ_EATER' | 'MILESTONE' | 'REGULAR' | 'REVENANT' | 'CENTENARY'


/******************* BADGE TYPES *******************/

/* PERSISTENT_ELEPHANT */
export interface PersistentElephantBadgeData {
    consecutiveDays: number;
    lastDay: string;
}
export const defaultPersistentElephantBadgeData: PersistentElephantBadgeData = {
    consecutiveDays: 0,
    lastDay: ''
};

/* PERFECT_PERFORMANCE */
export interface PerfectPerformanceBadgeData { }
export const defaultPerfectPerformanceBadgeData: PerfectPerformanceBadgeData = {};

/* INDEPENDENT_ELEPHANT */
export interface IndependentElephantBadgeData { }
export const defaultIndependentElephantBadgeData: IndependentElephantBadgeData = {};

/* FIRST_STEPS */
export interface FirstStepsBadgeData { }
export const defaultFirstStepsBadgeData: FirstStepsBadgeData = {};

/* PILLAR_OF_CONFIDENCE */
export interface PillarOfConfidenceBadgeData { }
export const defaultPillarOfConfidenceBadgeData: PillarOfConfidenceBadgeData = {};

/* QUIZ_EATER */
export interface QuizEaterBadgeData {
    completedBlockCount: number;
    lastDay: string;
}
export const defaultQuizEaterBadgeData: QuizEaterBadgeData = {
    completedBlockCount: 0,
    lastDay: ''
};

/* MILESTONE */
export interface MilestoneBadgeData {
    completedBlockCount: number;
}
export const defaultMilestoneBadgeData: MilestoneBadgeData = {
    completedBlockCount: 0
};

/* REGULAR */
export interface RegularBadgeData {
    consecutiveDays: number;
    lastDay: string;
}
export const defaultRegularBadgeData: RegularBadgeData = {
    consecutiveDays: 0,
    lastDay: ''
};

/* REVENANT */
export interface RevenantBadgeData {
    lastDay: string;
}
export const defaultRevenantBadgeData: RevenantBadgeData = {
    lastDay: ''
};

/* CENTENARY */
export interface CentenaryBadgeData { }
export const defaultCentenaryBadgeData: CentenaryBadgeData = {};


/******************* DEFAULT BADGE SPECIFIC DATA *******************/

export const defaultBadgeSpecificData: Record<BadgeName, BadgeSpecificData> = {
    PERSISTENT_ELEPHANT: defaultPersistentElephantBadgeData,
    PERFECT_PERFORMANCE: defaultPerfectPerformanceBadgeData,
    INDEPENDENT_ELEPHANT: defaultIndependentElephantBadgeData,
    FIRST_STEPS: defaultFirstStepsBadgeData,
    PILLAR_OF_CONFIDENCE: defaultPillarOfConfidenceBadgeData,
    QUIZ_EATER: defaultQuizEaterBadgeData,
    MILESTONE: defaultMilestoneBadgeData,
    REGULAR: defaultRegularBadgeData,
    REVENANT: defaultRevenantBadgeData,
    CENTENARY: defaultCentenaryBadgeData
};