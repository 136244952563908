import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';
import { VersionGuard } from './shared/guards/version.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    canActivate: [VersionGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
  },
  {
    path: 'registration',
    loadComponent: () => import('./pages/registration/registration.page').then(m => m.RegistrationPage),
    canActivate: [VersionGuard],
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile/profile.page').then(m => m.ProfilePage),
    canActivate: [authGuard, VersionGuard],
  },
  {
    path: 'footprints',
    loadComponent: () => import('./pages/gamification/footprints/footprints.page').then(m => m.FootprintsPage),
  },
  {
    path: 'self-understanding',
    loadComponent: () => import('./pages/self-understanding/self-understanding.page').then(m => m.SelfUnderstandingPage),
  },
  {
    path: 'join',
    loadComponent: () => import('./pages/join/join.page').then(m => m.JoinPage),
    canActivate: [VersionGuard],
  },
  {
    path: 'join/:id',
    loadComponent: () => import('./pages/join/join.page').then(m => m.JoinPage),
    canActivate: [VersionGuard],
  },
  {
    path: ':id',
    loadComponent: () => import('./pages/live/live.page').then(m => m.LivePage),
    canActivate: [VersionGuard],
  },
  {
    path: 'scene/:id',
    loadComponent: () => import('./pages/scene/scene.page').then(m => m.ScenePage),
    canActivate: [VersionGuard],
  },
  {
    path: 'scene/:id/learning-statistics',
    loadComponent: () => import('./pages/scene/learning-statistics/learning-statistics.page').then(m => m.LearningStatisticsPage),
    canActivate: [VersionGuard],
  },
  {
    path: 'scene/:sceneId/selfassignment/:lessonId',
    loadComponent: () => import('./pages/scene/self-assignment/self-assignment.page').then(m => m.SelfAssignmentPage),
    canActivate: [VersionGuard],
  },
  {
    path: 'challenge/:challengeSessionId',
    loadComponent: () => import('./pages/challenge/challenge.page').then(m => m.ChallengePage),
    canActivate: [VersionGuard],
  },
  {
    path: 'email-confirmation/:token',
    loadComponent: () => import('./pages/email-confirmation/email-confirmation.page').then(m => m.EmailConfirmationPage),
  },
  {
    path: 'neptun-modal',
    loadComponent: () => import('./shared/neptun-modal/neptun-modal.page').then(m => m.NeptunModalPage),
  },
  {
    path: 'earned-badge-modal',
    loadComponent: () => import('./shared/components/earned-badge-modal/earned-badge-modal.page').then(m => m.EarnedBadgeModalPage),
  },
  {
    path: 'image-modal',
    loadComponent: () => import('./shared/components/image-modal/image-modal.page').then(m => m.ImageModalPage)
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'prefix',
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'prefix',
  }
];
