import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  translation: any = {};
  selectedLanguage = '';
  defaultLanguage = 'hu';
  languageVariants = {
    'hu': ['hu'],
    'en': ['en'],
    'de': ['de']
  };

  constructor(private http: HttpClient) { }

  async use(language: string): Promise<void> {
    try {
      const storedLanguage = await localStorage.getItem('lang');
      const deviceLanguage = this.getDeviceLanguage();
      await this.fetchTranslation(language || storedLanguage || deviceLanguage || this.defaultLanguage);
      await localStorage.setItem('lang', this.selectedLanguage);
    } catch (error) {
      await this.use(this.defaultLanguage);
    }
  }

  get(): string {
    return this.selectedLanguage;
  }

  private async fetchTranslation(lang: string) {
    const jsonPath = `assets/i18n/${lang}.json`;
    const translation = await this.http.get<{}>(jsonPath).pipe(take(1)).toPromise();
    this.translation = translation;
    this.selectedLanguage = lang;
  }

  private getDeviceLanguage(): string | undefined {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return undefined;
    }
    let deviceLanguage: any = window.navigator.languages ? window.navigator.languages[0] : null;
    deviceLanguage = deviceLanguage || window.navigator.language;
    if (typeof deviceLanguage === 'undefined') {
      return undefined;
    }
    if (deviceLanguage.indexOf('-') !== -1) {
      deviceLanguage = deviceLanguage.split('-')[0];
    }
    if (deviceLanguage.indexOf('_') !== -1) {
      deviceLanguage = deviceLanguage.split('_')[0];
    }
    for (const [key, value] of Object.entries(this.languageVariants)) {
      if (value.includes(deviceLanguage)) {
        deviceLanguage = key;
        break;
      }
    }
    return deviceLanguage;
  }
}
