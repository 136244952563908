import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule, Platform } from '@ionic/angular';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class ImageModalPage implements OnInit {
  @Input() imageUrl: string;
  modalId: string = 'image-modal';
  modal: HTMLIonModalElement;
  popStateListener: any;

  constructor(private platform: Platform) { }

  ngOnInit() {
  }

  closeModal() {
    this.modal?.dismiss(undefined, 'close');
  }

  ionViewDidEnter() {
    this.getModalInstance();
  }

  getModalInstance() {
    const modal = document.getElementById(this.modalId) as HTMLIonModalElement;
    if (modal) {
      this.modal = modal;
      this.closeModalOnBackButtonNavigation();
      modal.onDidDismiss().then(() => {
        this.ionModalOnDismiss();
      });
    }
  }

  ionModalOnDismiss() {
    if (!this.platform.is('cordova')) {
      if (this.popStateListener) {
        window.removeEventListener('popstate', this.popStateListener);
        this.popStateListener = null;
      }
    }
  }

  closeModalOnBackButtonNavigation() {
    if (!this.platform.is('cordova')) {
      history.pushState(null, '', location.href);
      this.popStateListener = async () => {
        this.modal?.dismiss();
      }
    };
    window.addEventListener('popstate', this.popStateListener);
  }

}
