import { Injectable } from '@angular/core';
import { Badge } from './gamification/gamification.service';
import { EarnedBadge } from '../models/gamification/EarnedBadges.model';
import { EarnedBadgeModalPage } from '../components/earned-badge-modal/earned-badge-modal.page';
import { ModalController } from '@ionic/angular';
import { ImageModalPage } from '../components/image-modal/image-modal.page';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private modalController: ModalController) { }


    async showBadgeModal(collectedBadgesInBlock: Badge[] = [], badgesFromFirebase: EarnedBadge[] = []) {
        const modal = await this.modalController.create({
            component: EarnedBadgeModalPage,
            cssClass: 'defaultModal badge-modal',
            id: 'badge-modal',
            backdropDismiss: true,
            componentProps: {
                collectedBadgesInBlock: collectedBadgesInBlock,
                badgesFromFirebase: badgesFromFirebase,
            },
        });
        await modal.present();
        return modal;
    }

    async showImageModal(imageUrl: string) {
        const modal = await this.modalController.create({
            component: ImageModalPage,
            cssClass: 'defaultModal image-modal',
            id: 'image-modal',
            backdropDismiss: true,
            componentProps: {
                imageUrl: imageUrl
            },
        });
        await modal.present();
        return modal;
    }




}
