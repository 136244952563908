import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, Subscription } from 'rxjs';
import { collection, FBaseService } from '../firebase/f-base.service';
import {
    BadgeName, defaultBadgeSpecificData, EarnedBadge, MilestoneBadgeData, PersistentElephantBadgeData,
    QuizEaterBadgeData, RegularBadgeData, RevenantBadgeData
} from '../../models/gamification/EarnedBadges.model';
import { TranslatePipe } from '../../translate/pipes/translate.pipe';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ModalService } from '../modal.service';
import { NavigationEnd, Router } from '@angular/router';
export interface Badge {
    name: string;
    description: string;
    earnedMessage: string;
    imagePath: string;
}

@Injectable({
    providedIn: 'root'
})

export class GamificationService {
    watchBadgesSub: Subscription;
    navigationListenerSub: Subscription;
    badgeModal: HTMLIonModalElement;
    lastSpecialBadges: EarnedBadge[] = [];
    private isWatcherInitialized = false;
    badges: Badge[] = [
        {
            name: 'PERSISTENT_ELEPHANT', // Kitartó elefánt
            description: 'PERSISTENT_ELEPHANT_DESCRIPTION',
            earnedMessage: 'PERSISTENT_ELEPHANT_EARNED',
            imagePath: 'assets/images/gamification/badges/PERSISTENT_ELEPHANT.png'
        },
        {
            name: 'PERFECT_PERFORMANCE', // Tökéletes teljesítmény
            description: 'PERFECT_PERFORMANCE_DESCRIPTION',
            earnedMessage: 'PERFECT_PERFORMANCE_EARNED',
            imagePath: 'assets/images/gamification/badges/PERFECT_PERFORMANCE.png'
        },
        {
            name: 'INDEPENDENT_ELEPHANT', // Önálló elefánt
            description: 'INDEPENDENT_ELEPHANT_DESCRIPTION',
            earnedMessage: 'INDEPENDENT_ELEPHANT_EARNED',
            imagePath: 'assets/images/gamification/badges/INDEPENDENT_ELEPHANT.png'
        },
        {
            name: 'FIRST_STEPS', // Első lépések
            description: 'FIRST_STEPS_DESCRIPTION',
            earnedMessage: 'FIRST_STEPS_EARNED',
            imagePath: 'assets/images/gamification/badges/FIRST_STEPS.png'
        },
        {
            name: 'PILLAR_OF_CONFIDENCE',
            description: 'PILLAR_OF_CONFIDENCE_DESCRIPTION',
            earnedMessage: 'PILLAR_OF_CONFIDENCE_EARNED',
            imagePath: 'assets/images/gamification/badges/PILLAR_OF_CONFIDENCE.png'
        },
        {
            name: 'QUIZ_EATER', // Kvízzabáló
            description: 'QUIZ_EATER_DESCRIPTION',
            earnedMessage: 'QUIZ_EATER_EARNED',
            imagePath: 'assets/images/gamification/badges/QUIZ_EATER.png'
        },
        {
            name: 'MILESTONE', // Mérföldkő
            description: 'MILESTONE_DESCRIPTION',
            earnedMessage: 'MILESTONE_EARNED',
            imagePath: 'assets/images/gamification/badges/MILESTONE.png'
        },
        {
            name: 'REGULAR', // Törzsvendég
            description: 'REGULAR_DESCRIPTION',
            earnedMessage: 'REGULAR_EARNED',
            imagePath: 'assets/images/gamification/badges/REGULAR.png'
        },
        {
            name: 'REVENANT', // Visszatérő
            description: 'REVENANT_DESCRIPTION',
            earnedMessage: 'REVENANT_EARNED',
            imagePath: 'assets/images/gamification/badges/REVENANT.png'
        },
        {
            name: 'CENTENARY', // Centenárium
            description: 'CENTENARY_DESCRIPTION',
            earnedMessage: 'CENTENARY_EARNED',
            imagePath: 'assets/images/gamification/badges/CENTENARY.png'
        }
    ];
    navigationSub: Subscription;
    constructor(
        private fBaseService: FBaseService,
        private tp: TranslatePipe,
        private router: Router,
        private modalService: ModalService
    ) { }

    async getAllBadgesForUser(userId: string): Promise<EarnedBadge[]> {
        return (await firstValueFrom(
            this.fBaseService.getDataByField<EarnedBadge>(collection.earnedBadges, [
                { fieldPath: 'metadata.createdBy.id', opStr: '==', value: userId },
            ], undefined, undefined, null, true)));
    }

    async getBadgeByNameForUser(userId: string, badgeName: string): Promise<EarnedBadge[]> {
        return (await firstValueFrom(
            this.fBaseService.getDataByField<EarnedBadge>(collection.earnedBadges, [
                { fieldPath: 'name', opStr: '==', value: badgeName },
                { fieldPath: 'metadata.createdBy.id', opStr: '==', value: userId },
            ], undefined, undefined, null, true)));
    }

    async getBadgesByNames(userId: string, badgeNames: string[]): Promise<EarnedBadge[]> {
        return await firstValueFrom(
            this.fBaseService.getDataByField<EarnedBadge>(collection.earnedBadges, [
                { fieldPath: 'name', opStr: 'in', value: badgeNames },
                { fieldPath: 'metadata.createdBy.id', opStr: '==', value: userId },
            ], undefined, undefined, null, true)
        );
    }

    async createBadgeForUser(badge: EarnedBadge): Promise<void> {
        try {
            if (!defaultBadgeSpecificData[badge.name]) {
                throw new Error(`Default data not defined for badge: ${badge.name}`);
            }

            badge.badgeSpecificData = defaultBadgeSpecificData[badge.name];
            await this.fBaseService.saveOrUpdateData(collection.earnedBadges, badge);
        } catch (error) {
            console.log(`Failed to create badge: ${badge.name}`, error);
            throw error;
        }
    }

    async initializeBadges(userId: string): Promise<EarnedBadge[]> {

        // Az összes meglévő badge lekérése a felhasználótól
        const existingBadges = await this.getAllBadgesForUser(userId);

        // Hiányzó badge-ek azonosítása
        const existingBadgeNames = existingBadges.map(badge => badge.name);
        const missingBadges = this.badges.filter(badge => !existingBadgeNames.includes(badge.name as BadgeName));

        // Hiányzó badge-ek létrehozása
        for (const badge of missingBadges) {
            const earnedBadge: EarnedBadge = {
                name: badge.name as BadgeName,
                id: '',
                count: 0,
                badgeSpecificData: defaultBadgeSpecificData[badge.name as BadgeName],
                present: false,
                metadata: {
                    createdAt: new Date().toISOString(),
                    lastUpdatedAt: new Date().toISOString(),
                    createdBy: { id: userId },
                    lastUpdatedBy: { id: userId },
                }
            };
            await this.createBadgeForUser(earnedBadge);
            existingBadges.push(earnedBadge); // Frissítsük a meglévő badge-ek tömbjét
        }

        return existingBadges; // Visszaadjuk az összes badge-et
    }

    getSortedBadges(badgeMap: Map<string, EarnedBadge>): Badge[] {
        const earned = this.badges.filter(badge => (badgeMap.get(badge.name)?.count || 0) > 0);
        const unearned = this.badges.filter(badge => !(badgeMap.get(badge.name)?.count || 0 > 0));

        const sortByTranslation = (a: Badge, b: Badge) => {
            const nameA = this.tp.transform(a.name) || a.name;
            const nameB = this.tp.transform(b.name) || b.name;
            return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
        };

        return [
            ...earned.sort(sortByTranslation),
            ...unearned.sort(sortByTranslation)
        ];
    }

    async initializeNavigationListener(userid: string) {
        this.unsubNavigationListener();
        this.navigationListenerSub = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(async () => {
                await this.processBadges(userid, this.lastSpecialBadges);
            });
    }

    unsubNavigationListener() {
        this.navigationListenerSub ? this.navigationListenerSub.unsubscribe() : null
    }

    unsubBadgesWatcher() {
        this.isWatcherInitialized = false;
        this.watchBadgesSub ? this.watchBadgesSub.unsubscribe() : null;
    }

    async processBadges(userId: string, badges: EarnedBadge[]) {
        console.log('processBadge triggered', badges);
        if (badges.length > 0) {
            let collectedBadgesInBlock: Badge[] = [];
            for (const badge of badges) {
                if (badge.name === 'CENTENARY') {
                    await this.processCentenaryBadge(userId, badge);
                } else if (badge.name === 'REVENANT') {
                    await this.processRevenantBadge(badge);
                } else if (badge.name === 'REGULAR') {
                    await this.processRegularBadge(badge);
                }
                if (badge.present) {
                    const badgeToPush = this.badges.find(f => f.name === badge.name);
                    if (badgeToPush) {
                        collectedBadgesInBlock.push(badgeToPush);
                    }
                }
            }
            const modal = document.getElementById('badge-modal');
            if (!modal && collectedBadgesInBlock.length > 0) {
                setTimeout(async () => {
                    this.badgeModal?.dismiss();
                    this.badgeModal = await this.modalService.showBadgeModal(collectedBadgesInBlock, badges);
                }, 1000);
            }
        }
    }


    async initializeBadgeWatcher(userId: string, initializeBadges?: boolean) { //ez csak a login pagen helyes belépéskor és az app componentben fut le
        if (this.isWatcherInitialized) {
            console.log('Badge watcher already initialized.');
            return;
        }
        this.isWatcherInitialized = true;
        if (initializeBadges) {
            await this.initializeBadges(userId);
        }
        this.initializeNavigationListener(userId);
        this.watchBadgesSub = this.watchSpecialBadges(userId, ['REGULAR', 'REVENANT', 'CENTENARY']).subscribe(async (badges) => {
            console.log('Badges from SUB:', badges);
            this.lastSpecialBadges = badges; // Frissítjük a legutóbbi adatokat
            await this.processBadges(userId, badges); // Új adatok feldolgozása
        });
    }

    // Figyeli a megadott Badge-ek present státuszváltozását - app.component.ts-ben van a subscribe
    watchSpecialBadges(userId: string, specialBadgeNames: BadgeName[]): Observable<EarnedBadge[]> {
        return this.fBaseService.getDataByField(collection.earnedBadges, [
            { fieldPath: 'metadata.createdBy.id', opStr: '==', value: userId }
        ], undefined, undefined, null, true).pipe(
            map((badges: EarnedBadge[]) =>
                badges.filter(badge => specialBadgeNames.includes(badge.name))
            ),
            distinctUntilChanged((prev, curr) => { //Csak a badge.present változást kell követni
                const prevPresent = prev.map(badge => badge.present).join(',');
                const currPresent = curr.map(badge => badge.present).join(',');
                return prevPresent === currPresent;
            })
        );
    }


    /*********** PROCESS BADGES ***********/

    async processRegularBadge(badge: EarnedBadge | undefined): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {
        if (!badge) {
            console.log('Regular badge not found for user!');
            return { error: 'Badge not found', achieved: false };
        }

        const { todayDate, yesterdayDate } = this.initializeTodayAndYesterday();
        const badgeSpecificData = badge.badgeSpecificData as RegularBadgeData;
        let badgeUpdated = false;
        let achieved = false;

        // Ha lastDay a mai nap, ne csináljunk semmit
        if (badgeSpecificData.lastDay === todayDate) {
            //  console.log('Regular badge already updated for today. Nothing to do.');
            return { achieved: false };
        }

        // Ha lastDay nincs beállítva, vagy nem a tegnapi nap, kezdjük újra
        if (!badgeSpecificData.lastDay || badgeSpecificData.lastDay !== yesterdayDate) {
            badgeSpecificData.lastDay = todayDate;
            badgeSpecificData.consecutiveDays = 1;
            badgeUpdated = true;
            console.log('Regular badge reset to 1 consecutive day.');
        } else if (badgeSpecificData.lastDay === yesterdayDate) {
            // Ha lastDay a tegnapi nap
            if (badgeSpecificData.consecutiveDays === 4) {
                badgeSpecificData.consecutiveDays = 5;
                badgeSpecificData.lastDay = todayDate;
                badge.count += 1;
                badge.present = true;
                badgeUpdated = true;
                achieved = true;
                console.log('Regular badge achieved! Count increased.');
            } else if (badgeSpecificData.consecutiveDays === 5) {
                badgeSpecificData.consecutiveDays = 1;
                badgeSpecificData.lastDay = todayDate;
                badgeUpdated = true;
                console.log('Regular badge reset after full cycle.');
            } else {
                badgeSpecificData.consecutiveDays += 1;
                badgeSpecificData.lastDay = todayDate;
                badgeUpdated = true;
                console.log(`Regular badge consecutive days increased to ${badgeSpecificData.consecutiveDays}`);
            }
        }

        if (badgeUpdated) {
            const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
            return { ...error && { error }, earnedBadge, achieved };
        }

        return { achieved };
    }

    async processRevenantBadge(badge: EarnedBadge | undefined): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {

        if (!badge) {
            console.log('Revenant badge not found for user!');
            return { error: 'Badge not found', achieved: false };
        }

        const { todayDate } = this.initializeTodayAndYesterday();
        const badgeSpecificData = badge.badgeSpecificData as RevenantBadgeData;
        let badgeUpdated = false;
        let achieved = false;

        if (!badgeSpecificData.lastDay) {
            badgeSpecificData.lastDay = todayDate;
            badgeUpdated = true;
            console.log('Revenant badge initialized with today\'s date.');
        } else {
            const daysElapsed = Math.floor(
                (new Date(todayDate).getTime() - new Date(badgeSpecificData.lastDay).getTime()) / (1000 * 60 * 60 * 24)
            );

            if (daysElapsed >= 5) {
                badgeSpecificData.lastDay = todayDate;
                badge.count += 1;
                badge.present = true;
                badgeUpdated = true;
                achieved = true;
                console.log('Revenant badge achieved! Count increased.');
            } else if (badgeSpecificData.lastDay !== todayDate) {
                badgeSpecificData.lastDay = todayDate;
                badgeUpdated = true;
                console.log('Revenant badge updated with today\'s date.');
            }
        }

        if (badgeUpdated) {
            const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
            return { ...error && { error }, earnedBadge, achieved };
        }

        return { achieved };
    }

    async processCentenaryBadge(userId: string, badge: EarnedBadge | undefined): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {
        if (!badge) {
            console.log('Centenary badge not found for user!');
            return { error: 'Badge not found', achieved: false };
        }

        if (badge.count === 1) {
            return { achieved: false };
        }

        try {
            let firstLogin = await this.fBaseService.getFirstLoginDate(userId);
            console.log(firstLogin)
            if (!firstLogin) {
                return { error: 'No login found', achieved: false };
            }
            const daysElapsed = this.calculateDaysSince(firstLogin);
            console.log(`Days elapsed since first login: ${daysElapsed}`, 'firstlogin: ', firstLogin);

            // Ha 100 napnál több telt el
            if (daysElapsed >= 100) {
                badge.count += 1;
                badge.present = true;
                const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
                return { ...error && { error }, earnedBadge, achieved: true };
            }

            return { achieved: false }; // Még nem telt el 100 nap
        } catch (error) {
            console.log('Error checking Centenary badge:', error);
            return { error, achieved: false };
        }
    }

    async processMilestoneBadge(badge: EarnedBadge | undefined): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {

        if (!badge) {
            console.log('Milestone badge not found for user!');
            return { error: 'Milestone badge not found', achieved: false };
        }

        const milestoneData = badge.badgeSpecificData as MilestoneBadgeData;
        let badgeUpdated = false;
        let achieved = false;

        if (badge.count !== 1) {
            milestoneData.completedBlockCount += 1; // Blokkszámláló növelése
            badgeUpdated = true;

            if (milestoneData.completedBlockCount === 50) {
                badge.count = 1; // Számláló frissítése
                achieved = true; // Jelzés a szülő komponensnek
            }
        }

        if (badgeUpdated) {
            const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
            return { ...error && { error }, earnedBadge, achieved };
        }

        return { achieved };
    }

    async processFirstStepsBadge(badge: EarnedBadge | undefined): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {

        if (!badge) {
            console.log('First Steps badge not found for user!');
            return { error: 'Badge not found', achieved: false };
        }

        let badgeUpdated = false;
        let achieved = false;

        // Ha a count 0, növeljük, és a badge megszerzésre kerül
        if (badge.count === 0) {
            badge.count += 1;
            badgeUpdated = true;
            achieved = true;
            console.log('First Steps badge achieved for the first time.');
        }

        if (badgeUpdated) {
            const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
            return { ...error && { error }, earnedBadge, achieved };
        }

        return { achieved };
    }

    async processQuizEaterBadge(badge: EarnedBadge | undefined): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {

        if (!badge) {
            console.log('Quiz-Eater badge not found for user!');
            return { error: 'Quiz-Eater badge not found', achieved: false };
        }

        const { todayDate } = this.initializeTodayAndYesterday();
        const quizEaterData = badge.badgeSpecificData as QuizEaterBadgeData;
        let badgeUpdated = false;
        let achieved = false;

        if (quizEaterData.lastDay !== todayDate) {
            quizEaterData.completedBlockCount = 1;
            quizEaterData.lastDay = todayDate;
            badgeUpdated = true;
            console.log('Reset completedBlockCount to 1 for a new day.');
        } else {
            if (quizEaterData.completedBlockCount === 2) {
                quizEaterData.completedBlockCount = 3;
                badge.count += 1;
                achieved = true;
                badgeUpdated = true;
            } else if (quizEaterData.completedBlockCount !== 3) {
                quizEaterData.completedBlockCount += 1;
                badgeUpdated = true;
                console.log(`Incremented quizEater completedBlockCount to ${quizEaterData.completedBlockCount}`);
            }
        }

        if (badgeUpdated) {
            const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
            return { ...error && { error }, earnedBadge, achieved };
        }

        return { achieved };
    }

    async processPerfectPerformanceBadge(badge: EarnedBadge | undefined, totalQuestions: number, correctAnswers: number): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {

        if (!badge) {
            console.log('Perfect Performance badge not found for user!');
            return { error: 'Badge not found', achieved: false };
        }

        let badgeUpdated = false;
        let achieved = false;

        if (totalQuestions === correctAnswers) {
            badge.count += 1;
            badgeUpdated = true;
            achieved = true;
        }

        if (badgeUpdated) {
            const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
            return { ...error && { error }, earnedBadge, achieved };
        }

        return { achieved };
    }

    async processPersistentElephantBadge(badge: EarnedBadge | undefined): Promise<{ error?: any; earnedBadge?: EarnedBadge; achieved: boolean }> {

        if (!badge) {
            console.log('Badge not found for user!');
            return { error: 'Badge not found', achieved: false };
        }

        const { todayDate, yesterdayDate } = this.initializeTodayAndYesterday();
        const badgeSpecificData = badge.badgeSpecificData as PersistentElephantBadgeData;
        let badgeUpdated = false;
        let achieved = false;

        if (badgeSpecificData.lastDay === todayDate) {
            //  console.log('Badge already updated for today. Nothing to do.');
            return { achieved: false };
        }

        // Ha nincs lastDay, vagy nem a tegnapi nap, kezdjük újra
        if (!badgeSpecificData.lastDay || badgeSpecificData.lastDay !== yesterdayDate) {
            badgeSpecificData.lastDay = todayDate;
            badgeSpecificData.consecutiveDays = 1;
            badge.present = false;
            badgeUpdated = true;
            console.log('Badge reset to 1 consecutive day.');
        } else if (badgeSpecificData.lastDay === yesterdayDate) {
            // Tegnapi nap esetén
            if (badgeSpecificData.consecutiveDays === 4) {
                badgeSpecificData.consecutiveDays = 5;
                badgeSpecificData.lastDay = todayDate;
                badge.count += 1;
                badge.present = true;
                badgeUpdated = true;
                achieved = true;
                console.log(badge.name + ' EARNED', badge);
            } else if (badgeSpecificData.consecutiveDays === 5) {
                badgeSpecificData.consecutiveDays = 1;
                badgeSpecificData.lastDay = todayDate;
                badge.present = false;
                badgeUpdated = true;
                console.log('Badge reset to 1 consecutive day after full cycle.');
            } else {
                badgeSpecificData.consecutiveDays += 1;
                badgeSpecificData.lastDay = todayDate;
                badge.present = false;
                badgeUpdated = true;
                console.log(`Consecutive days increased to ${badgeSpecificData.consecutiveDays}`);
            }
        }

        if (badgeUpdated) {
            const { error, earnedBadge } = await this.saveBadgeToFirebase(badge);
            return { ...error && { error }, earnedBadge, achieved };
        }

        return { achieved };
    }

    async saveBadgeToFirebase(badge: EarnedBadge): Promise<{ error?: any; earnedBadge?: EarnedBadge }> {
        try {
            await this.fBaseService.saveOrUpdateData(collection.earnedBadges, badge);
            return { earnedBadge: badge };
        } catch (error) {
            return { error };
        }
    }

    /** HELPER FUNCTIONS AND METHODS **/

    /* Inicializálja a mai és tegnapi dátumokat Firebase-kompatibilis ISO formátumban */
    initializeTodayAndYesterday(): { todayDate: string; yesterdayDate: string } {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return {
            todayDate: this.formatDateToISO(today),
            yesterdayDate: this.formatDateToISO(yesterday)
        };
    }

    /* Formáz egy dátumot Firebase-kompatibilis `T00:00:00.000Z` formátumba */
    formatDateToISO(date: Date): string {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
    }

    /* Kiszámítja két dátum közötti napok számát */
    calculateDaysBetween(start: string, end: string): number {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const diffInMs = endDate.getTime() - startDate.getTime();
        return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    }

    /* Kiszámolja egy dátum óta eltelt napok számát*/
    calculateDaysSince(startDate: string): number {
        // Konvertáljuk a kezdő dátumot `Date` objektummá
        const start = new Date(startDate);
        const today = new Date();

        // Formázzuk mindkét dátumot `T00:00:00.000Z` formátumra
        const formatDateToISO = (date: Date): string =>
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();

        const formattedStartDate = formatDateToISO(start);
        const formattedTodayDate = formatDateToISO(today);

        // Számoljuk ki az eltelt napokat
        const daysElapsed = Math.floor(
            (new Date(formattedTodayDate).getTime() - new Date(formattedStartDate).getTime()) / (1000 * 60 * 60 * 24)
        );
        return daysElapsed;
    }
}