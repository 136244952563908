import { Component, EnvironmentInjector, inject, NgZone } from '@angular/core';
import { AlertController, IonicModule, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule } from './shared/translate/translate.module';
import { NetworkService } from './shared/services/network.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { LoggerService } from './shared/services/logger.service';
import { TranslatePipe } from './shared/translate/pipes/translate.pipe';
import { AuthService } from './shared/services/auth.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { VersionService } from './shared/services/version.service';
import { collection, FBaseService } from './shared/services/firebase/f-base.service';
import { User } from '../../../models/User';
import { FcmService } from './shared/services/fcm.service';
import { register } from 'swiper/element/bundle';
import { GamificationService } from './shared/services/gamification/gamification.service';
import { NavigationEnd, Router } from '@angular/router';


// declare const ImpacTracking: any;
declare const universalLinks: any;
register();


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})
export class AppComponent {
  networkStatus: boolean = true;
  networkSub: Subscription;
  watchBadgesSub: Subscription;
  user: firebase.default.User | null | undefined;
  version: string | null;
  popStateListener: any;

  public environmentInjector = inject(EnvironmentInjector);
  constructor(
    private platform: Platform,
    private translate: TranslatePipe,
    private logger: LoggerService,
    private networkService: NetworkService,
    private toastController: ToastController,
    private authService: AuthService,
    private navController: NavController,
    private zone: NgZone,
    private modalController: ModalController,
    private alertController: AlertController,
    private deeplinks: Deeplinks,
    private location: Location,
    private versionService: VersionService,
    private fbService: FBaseService,
    private fcmService: FcmService,
    private gamificationService: GamificationService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      // this.translateService.use('hu');
      if (this.isRunningOnMobileDevice()) {
        this.versionService.subscribeToVersionChanges();
      }
      try {
        const user = await firstValueFrom(this.authService.currentUserObservable());
        if (user) {
          this.logger.setEmail(user.email);
          this.logger.setUserId(user.uid);
          const firebaseUser = (await firstValueFrom(this.fbService.getById(collection.users, user?.uid))) as User;
          if (firebaseUser) {
            await this.fcmService.checkNotifications(firebaseUser);
            if (firebaseUser?.neptunCode) {
              this.logger.setEduId(firebaseUser.neptunCode);
            }
          }
          this.gamificationService.initializeBadgeWatcher(user.uid, false);
        }
      } catch (error) {
        this.logger.handleError(error);
      }
      // await this.networkService.checkConnectedStatus();
      // await this.networkService.listenForConnectionChanges();
      // this.listenNetworkChanges();
      // if (this.platform.is('cordova') && this.platform.is('ios')) { setTimeout(() => { this.checkTracking(); }, 1000); }
      if (this.isRunningOnMobileDevice()) {
        this.initUniversalLinks();
        await this.handleBackButton();
      }
    });
  }

  isRunningOnMobileDevice(): boolean {
    return this.platform.is('cordova');
  }

  async handleBackButton() {
    this.platform?.backButton?.subscribeWithPriority(10, async () => {
      const alert = await this.alertController?.getTop();
      const modal = await this.modalController?.getTop();
      if (modal || alert) {
        modal ? await modal?.dismiss() : null;
        alert ? await alert?.dismiss() : null;
      } else {
        this.location.back();
      }
    });
  }

  initUniversalLinks() {
    this.deeplinks
      .route({
        'x': 'x',
      })
      .subscribe(
        match => {
          const path = String(match.$link.path) || null;
          const host = String(match.$link.host) || null;
          const url = this.platform.is('android') ? (path ? '/' + host + path : host || '') : path || '';
          // if (this.platform.is('android')) {
          //   url = (path ? '/' + host + path : host || '');
          // } else {
          //   url = (path || '');
          // }
          this.logger.addLogItem('DEEPLINK_ROUTE', { match, url });
          if (url) {
            this.zone.run(() => {
              this.navController.navigateRoot(url);
            });
          }
        },
        nomatch => { }
      );
    universalLinks.subscribe(
      'ul_appOpened',
      (link: any) => {
        const url = String(link.path) || null;
        this.logger.addLogItem('UNIVERSAL_LINKS', { link, url });
        if (url) {
          this.zone.run(() => {
            this.navController.navigateRoot(url);
          });
        }
      },
      (error: any) => {
        this.logger.handleError(error, true);
      }
    );
  }

  // checkTracking() {
  //   ImpacTracking.requestTracking(
  //     (success: any) => {
  //       console.log(success);
  //       if (success === true) {
  //         this.logger.setLogLevel('ONLINE');
  //       } else {
  //         this.logger.setLogLevel('NONE');
  //       }
  //     },
  //     (error: any) => {
  //       this.logger.handleError(error, true);
  //     }
  //   );
  // }

  listenNetworkChanges() {
    this.networkSub = this.networkService.subscribeConnectionStatus().subscribe(async (conn: any) => {
      this.networkStatus = conn;
      let hasFalseValue = false;
      if (this.networkStatus === false) {
        this.logger.showToast(this.translate.transform('NO_INTERNET'), 'infinite', 'bottom', 'dark', 'globe', true);
        this.logger.addLogItem('NO_INTERNET_CONNECTION');
        hasFalseValue = true;
      } else {
        const toast = await this.toastController?.getTop();
        if (toast) {
          toast.dismiss();
        }
      }
    });
  }

  ionViewDidLeave() {
    this.platform?.backButton?.unsubscribe();
  }
}
