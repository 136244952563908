import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { TranslatePipe } from '../translate/pipes/translate.pipe';
import { Platform, ToastController } from '@ionic/angular';
import * as semver from 'semver';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    versionSubject = new BehaviorSubject<boolean | null>(null); // Kezdetben null, ha nem tudjuk az állapotot
    versionIsValid$ = this.versionSubject.asObservable(); // Ezt használhatjuk a feliratkozáshoz
    versionToast: any = null;
    toastVisible = false;

    constructor(
        private afs: AngularFirestore,
        private platform: Platform,
        private logger: LoggerService,
        private tp: TranslatePipe,
        private toastController: ToastController
    ) {
    }
    navigationSub: any;

    // Verzió figyelése Firestore-ból
    private getNewestVersion(): Observable<any> {
        return this.afs.collection('SystemConfig', ref => {
            let query: CollectionReference | Query = ref;
            query = query.where('isActive', '==', true);
            query = query.orderBy('metadata.lastUpdatedAt', 'desc');
            return query;
        }).valueChanges();
    }

    // Feliratkozás a verzió változásokra
    subscribeToVersionChanges() {
        const currentVersion = environment.version;
        this.getNewestVersion().subscribe(systemConfig => {
            const minimumRequiredVersion = systemConfig[0]?.studentApp?.minimumRequiredVersion || '';
            console.log('App current version: ' + currentVersion +
                '\nRequired minimum app version: ' + minimumRequiredVersion);
            const isValid = minimumRequiredVersion
                ? semver.gte(currentVersion, minimumRequiredVersion)
                : true; // Ha nincs minimum verzió, true

            if (isValid) {
                this.versionToast?.dismiss(); // Ha van toast, elrejti
            } else {
                const path = window?.location?.pathname;
                if (path === '/login') {
                    this.showVersionToast();
                } else {
                    //TODO Jelenleg akkor jön fel leghamarabb a toast, ha egy új oldalra navigál.
                    // Tehát, ha pl pihen a scene page-en, és közben megváltozik a verzió, nem fogja kidobni, hanem
                    // majd akkor, ha már másik oldalra lép. Ezáltal biztosítva van az is, hogy ha folyamatban van
                    // egy tanulás, akkor ne dobja ki rögtön, hanem majd ha valahogy elnavigál onnan vagy kész lesz.
                    // szerintem ez kb jó megoldás, lehetne még részletesebben, de most jó így.

                    //Ha nincs folyamatban tanulás, csak akkor navigáljon el
                    /*  if (!path?.includes('liveassignment') && !path?.includes('selfassignment')) {
                      this.router.navigate(['/login']);
                     } */
                }
            }
            this.versionSubject.next(isValid); // Verzió állapot frissítése
        });

    }

    checkStore() {
        let storeURL: string = '';
        if (this.platform.is('ios')) {
            storeURL = 'https://apps.apple.com/hu/app/szte-aeterna/id6502769983';
        }
        if (this.platform.is('android')) {
            storeURL = 'https://play.google.com/store/apps/details?id=hu.bilickiv.aeterna';
        }
        return storeURL;
    }
    async showVersionToast() {
        // Ellenőrizzük, hogy a toast már látható-e
        if (this.toastVisible) {
            return;
        }
        this.toastVisible = true;
        if (this.versionToast) {
            this.versionToast.dismiss(); // Előző toastot elrejti
        }
        this.logger.addLogItem('APP_VERSION_UPDATE_NEEDED');
        this.versionToast = await this.toastController.create({
            message: this.tp.transform('UPDATE_APP_VERSION'),
            color: 'dark',
            mode: 'ios',
            position: 'bottom',
            layout: 'stacked',
            cssClass: 'version-toast',
            buttons: [
                {
                    text: this.tp.transform('UPDATE')?.toUpperCase(),
                    role: 'update'
                }
            ],
        });

        /* EZ MEGOLDJA az iPhone 6 és 6s-en a hibásan balra zárt gombokat, sehogy máshogy nem lehet ezt a shadow classt elérni */
        const buttonGroup = this.versionToast?.shadowRoot?.querySelector('.toast-button-group') as HTMLElement;
        if (buttonGroup) {
            buttonGroup.style.justifyContent = 'flex-end';
        }
        /***********************************************************************************************************************/

        await this.versionToast.present();

        const { role } = await this.versionToast.onDidDismiss();
        this.toastVisible = false;

        if (role === 'update') {
            const url = this.checkStore();
            this.logger.addLogItem('APP_VERSION_UPDATE_PRESSED');
            if (url) {
                window.location.href = url;
                // window.open(url, '_blank');// új oldalon nyitja, meg kell nézni melyik a jobb, de úgy tűnik jó a href csere (fenti)
            }
        }
    }
}
