<ion-grid class="badgeGrid centering" *ngIf="collectedBadgesInBlock.length>0">
  <ion-row>
    <ion-col (click)="swipe('left')" *ngIf="collectedBadgesInBlock.length>1"
      class="ion-no-padding ion-text-start flexVerticalCenter" size="0.5">
      <ion-icon id="gamificationStatsWindowIcon" *ngIf="swiperInstance?.isBeginning === false" class="iconInBadgeSwiper"
        color="primary" name="chevron-back"></ion-icon>
    </ion-col>
    <ion-col class="ion-no-padding" [size]="collectedBadgesInBlock && collectedBadgesInBlock.length > 1 ? '11' : '12'">
      <div class="flexVerticalCenter flexCol ion-text-center badgeModalWrapper">
        <swiper-container id="swiper" #swiper (swiperinit)="swiperInit($event)"
          (swiperslidechange)="updateSwiperInstance($event)">
          <swiper-slide [id]="badge?.name ? badge.name : 'slide'+i"
            *ngFor="let badge of collectedBadgesInBlock; let i = index">
            <ion-label color="dark">{{ badge.name | t }}</ion-label>
            <img style="margin-top: 5px;" class="badge" [src]="badge.imagePath" />
            <div class="badgeEarnedMessage">
              <ion-text color="primary">{{ badge.earnedMessage| t }}</ion-text>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </ion-col>
    <ion-col (click)="swipe('right')" *ngIf="collectedBadgesInBlock.length>1"
      class="ion-no-padding ion-text-end flexVerticalCenter" size="0.5">
      <ion-icon id="gamificationStatsWindowIcon" *ngIf="swiperInstance?.isEnd === false" class="iconInBadgeSwiper"
        color="primary" name="chevron-forward"></ion-icon>
    </ion-col>
  </ion-row>
</ion-grid>