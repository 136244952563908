import { Injectable, inject } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { StorageItems } from '../constants/storage-items.constants';
import { Analytics, logEvent, setUserId } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { StorageService } from './storage.service';
import { firstValueFrom } from 'rxjs';
import { TranslatePipe } from '../translate/pipes/translate.pipe';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export class LogItem {
  eduId: any;
  id: any;
  title: any;
  data: any;
  subtitle: any;
  type: any;
  uid: any;
  versionNumber: any;
  versionCode: any;
  packageName: any;
  cordova: any;
  platform: any;
  timeStamp: any;
  email: any;
  currentUrl: any;
  appName: any;
  constructor(id = null, title = null, data = null, subtitle = null, type = null, uid = null, eduId = null, versionNumber = null, versionCode = null, packageName = null, cordova = false, platform = null, timeStamp = null, email = null, currentUrl = null, appName = null) {
    this.id = id;
    this.title = title ? (typeof title !== 'string' ? JSON.stringify(title) : title) : title;
    this.data = data;
    this.subtitle = subtitle ? (typeof subtitle !== 'string' ? JSON.stringify(subtitle) : subtitle) : subtitle; //itt ha a stringify-t kivesszük, szépen objectekként jelennek meg
    this.type = type;
    this.uid = uid;
    this.eduId = eduId;
    this.versionNumber = versionNumber;
    this.versionCode = versionCode;
    this.packageName = packageName;
    this.cordova = cordova;
    this.platform = platform;
    this.timeStamp = timeStamp || new Date().toISOString();
    this.email = email;
    this.currentUrl = currentUrl;
    this.appName = appName;
  }
}
@Injectable({ providedIn: 'root' })
export class LoggerService {
  toastPresent = false;
  private toastCache: any = [];
  prevToastMsg: string = '';
  user: firebase.default.User | null | undefined;

  private isCordova: any;
  private offlineLog: LogItem[] = [];
  private eduId: any;
  private uid: any;
  private versionCode: any;
  private versionNumber: any;
  private packageName: any;
  private appName: any;
  private plt: any;
  private logCollectionName = 'SystemLog';
  private loading?: HTMLIonLoadingElement;
  private LOGLEVEL: 'NONE' | 'OFFLINE' | 'ANALYTICS' | 'ONLINE' = 'ONLINE';
  private email: any;
  private analytics: Analytics = inject(Analytics);
  practiceParams: { fields: string[]; topics: string[] } = { fields: [], topics: [] };

  constructor(
    private alertController: AlertController,
    private router: Router,
    private toastController: ToastController,
    private platform: Platform,
    private loadingController: LoadingController,
    private storage: StorageService,
    private modalController: ModalController,
    private afs: AngularFirestore,
    private tp: TranslatePipe,
    private afAuth: AngularFireAuth
  ) {
    this.platform.ready().then(async () => {
      await this.init();
      this.plt = this.platform.platforms().join(',');
      this.isCordova = this.platform.is('cordova');
    });
  }

  private init(): Promise<void> {
    return new Promise(async resolve => {
      try {
        const offlineLog = await this.loadLog();
        if (offlineLog!) {
          this.offlineLog = offlineLog;
        } else {
          this.offlineLog = [];
        }
        resolve();
      } catch (error) {
        this.offlineLog = [];
        resolve();
      }
    });
  }

  setLogLevel(level: any) {
    this.LOGLEVEL = level;
  }

  setPracticeParams(value: boolean, type: 'fields' | 'topics', name: string) {
    if (value) {
      if (!this.practiceParams[type].includes(name)) {
        this.practiceParams[type].push(name);
      }
    } else {
      this.practiceParams[type] = this.practiceParams[type].filter(str => str != name);
    }
  }

  getPracticeParams() {
    return this.practiceParams;
  }

  setEduId(eduId: any) {
    this.eduId = eduId;
  }

  setUserId(uid: any) {
    if (uid) {
      this.uid = uid;
      setUserId(this.analytics, this.uid);
      if (this.LOGLEVEL === 'ONLINE') {
        this.uploadOldLog();
      }
    }
  }

  getUserId() {
    return this.uid || null;
  }

  getEduId() {
    return this.eduId || null;
  }

  setVersionCode(versionCode: any) {
    this.versionCode = versionCode;
  }

  getVersionCode(): any {
    return this.versionCode || null;
  }

  setVersionNumber(versionNumber: any) {
    this.versionNumber = versionNumber;
  }

  getVersionNumber(): any {
    return this.versionNumber || environment.version || null;
  }

  setPackageName(packageName: any) {
    this.packageName = packageName;
  }

  getPackageName(): any {
    return this.packageName || null;
  }

  setAppName(appName: any) {
    this.appName = appName;
  }

  getAppName(): any {
    return this.appName || null;
  }

  setEmail(email: any) {
    this.email = email;
  }

  getEmail(): any {
    return this.email || null;
  }

  currentUserObservable() {
    return this.afAuth.authState;
  }

  async checkExistingUser() {
    const user = await firstValueFrom(this.currentUserObservable()).catch(err => {
      return null;
    });
    if (user && user?.uid) {
      this.storageSet(StorageItems.USER, this.user);
      return user;
    } else {
      this.storageRemove(StorageItems.USER);
      return null;
    }
  }

  storageSet(key: string, value: any) {
    if (key === 'user') {
      const u = { uid: value?.uid, email: value?.email, isAnonymous: value?.isAnonymous };
      localStorage.setItem(key, JSON.stringify(u));
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  storageGet(key: string) {
    return JSON.parse(localStorage.getItem(key)!);
  }

  storageRemove(key: string) {
    localStorage.removeItem(key);
  }

  private logAnalytics(eventName: any, params: any = {}) {
    if (this.getUserId() !== null) {
      //  params.eduId = this.getEduId();
      params.uid = this.getUserId();
      params.when = new Date().toISOString();
      params.versionNumber = this.getVersionNumber();
      params.versioncode = this.getVersionCode();
      params.packageName = this.getPackageName();
      // params.subtitle =
      logEvent(this.analytics, eventName, params);
      // console.log(eventName);
    }
  }

  getSystemLog(uid: any, startDate: Date, endDate: Date, onlyErrors: boolean = false) {
    return this.afs
      .collection(this.logCollectionName, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('uid', '==', uid);
        query = query.where('timeStamp', '<', endDate.toISOString());
        query = query.where('timeStamp', '>=', startDate.toISOString());
        if (onlyErrors) {
          query = query.where('type', '==', 'error');
        }
        query = query.orderBy('timeStamp', 'desc');
        return query;
      })
      .valueChanges();
  }

  private async uploadOldLog() {
    if (this.offlineLog.length > 0) {
      let error = null;
      for (var i = 0, len = this.offlineLog.length; i < len; ++i) {
        const oldLogItem = this.offlineLog[i];
        const newLogItem = {
          id: null,
          title: oldLogItem.title,
          data: oldLogItem.data,
          subtitle: oldLogItem.subtitle,
          type: oldLogItem.type,
          uid: this.getUserId(),
          eduId: this.getEduId(),
          versionNumber: this.getVersionNumber(),
          versionCode: this.getVersionCode(),
          packageName: this.getPackageName(),
          cordova: this.isCordova,
          platform: this.plt,
          currentUrl: this.router.url,
          email: this.email,
          appName: this.getAppName(),
          timeStamp: new Date(oldLogItem.timeStamp).toISOString(),
        };
        try {
          await this.addSystemLog(newLogItem, false);
        } catch (e) {
          error = e;
        }
      }
      if (!error) {
        this.offlineLog = [];
        this.storeLog();
      }
    }
  }

  async addLogItem(title: any = null, data: any = null, subtitle: any = null, type: any = 'info', timeStamp: any = null) {
    const item = new LogItem(null, title, data, subtitle, type, this.getUserId(), this.getEduId(), this.getVersionNumber(), this.getVersionCode(), this.getPackageName(), this.isCordova, this.plt, timeStamp, this.email, this.router.url as any, this.getAppName());
    if (item?.data instanceof Object && item?.data?.constructor === Object) {
      Object.keys(item?.data).map((value: any, i: number) => {
        if (typeof item?.data[value] === 'undefined') {
          //firebase nem szereti az undefined-ot, nehogy elszálljon
          delete item?.data[value];
          item.data[value] = null;
        }
      });
    }
    try {
      await this.addSystemLog(item);
    } catch (error) {
      console.error(error);
    }
  }

  private addSystemLog(item: LogItem, isNew: boolean = true): Promise<void> {
    // console.log(JSON.stringify(item));
    return new Promise(async (resolve, reject) => {
      let error = null;
      let analytics = false;
      if (this.LOGLEVEL === 'NONE') {
        resolve();
        return;
      }
      if (this.LOGLEVEL === 'ANALYTICS') {
        analytics = true;
      }
      if (this.LOGLEVEL === 'ONLINE') {
        analytics = true;
        try {
          item.id = this.afs.createId();
          await this.afs.collection(this.logCollectionName).doc(item.id).set(Object.assign({}, item));
        } catch (e) {
          error = e;
        }
      }
      if (this.LOGLEVEL === 'OFFLINE' || (error && isNew) || item.uid === null) {
        this.pushOfflineLog(item);
      }
      if (analytics) {
        this.logAnalytics(item.type === 'error' ? 'error_happened' : item.title.toLowerCase());
      }
      if (error) {
        reject(error);
        return;
      }
      resolve();
      return;
    });
  }

  private async pushOfflineLog(item: LogItem) {
    try {
      this.offlineLog.push(item);
      await this.storeLog();
    } catch (error) {
      console.error(error);
    }
  }

  async hideToast() {}
  async showToast(message: any = null, duration: number | 'infinite' = 1000, position: 'top' | 'bottom' | 'middle' = 'top', color: string = 'dark', icon?: any, okButton: any = false) {
    if (this.prevToastMsg !== message) {
      this.prevToastMsg = message;
      let toast = await this.toastController.create({
        message: message,
        color: color,
        mode: 'ios',
        position: position,
        cssClass: 'ion-text-center',
      });
      if (duration !== 'infinite') {
        toast.duration = duration;
      }
      if (icon) {
        toast.icon = icon;
      }
      if (okButton) {
        toast.buttons = [
          {
            text: 'OK',
            role: 'cancel',
          },
        ];
      }
      this.toastCache.unshift(toast);
      if (!this.toastPresent) {
        this.toastPresent = true;
        this.checkToastCache();
      }
    }
  }

  async checkToastCache() {
    if (this.toastCache.length > 0) {
      const toast = this.toastCache.pop();
      await toast.present();
      this.toastPresent = true;
      await toast.onDidDismiss();
      this.prevToastMsg = '';
      this.toastPresent = false;
      this.checkToastCache();
    }
  }

  async closeModal() {
    try {
      let modal = await this.modalController.getTop();
      if (modal) {
        await modal.dismiss();
        this.closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  convertNestedArray(values: any, type: 'toForm' | 'toFirebase') {
    let convertedValues: any;
    let originalValues: any;
    if (type === 'toFirebase') {
      convertedValues = values?.map((innerArray: any) => {
        const obj: { [key: string]: boolean | null | undefined } = {};
        innerArray?.forEach((value: any, index: number) => {
          obj[`${index + 0}`] = value;
        });
        return obj;
      });
      return convertedValues;
    } else if (type == 'toForm') {
      originalValues = values?.map((obj: any) => {
        const innerArray: (boolean | null | undefined)[] = [];
        for (let i = 0; i <= Object.keys(obj)?.length; i++) {
          innerArray?.push(obj[`${i}`]);
        }

        return innerArray;
      });
      return originalValues;
    }
  }

  async handleError(error: any, show = false, errorText?: string) {
    console.log(error);
    const terr = new Error();
    const errorCodeText = this.parseErrorCode(error.code || '');
    const header = errorCodeText?.header || error.code || error.header || 'ERROR';
    const title = errorCodeText?.title || error.msg || error.message || error.data || error.error || error;
    let subtitle: string;
    if (error.error && typeof error.error === 'object') {
      subtitle = error.error.error_message || error.error.error || 'SOMETHING_WRONG';
    } else {
      subtitle = title;
    }
    if (errorText) {
      subtitle = errorText;
    }
    await this.addLogItem(title, null, error, 'error');
    if (show) {
      const alert = await this.alertController.create({
        header: header,
        subHeader: subtitle,
        // message: subtitle,
        backdropDismiss: false,
        buttons: ['OK'],
      });
      await alert.present();
    }
    try {
      const bool = JSON.stringify(error).includes('permission-denied');
      if (bool) {
        // this.showToast(this.tp.transform('SOMETHING_WRONG'), 2500, 'top');
      }
    } catch (error) {
      console.log(error);
    }
  }

  parseErrorCode(errorCode: string): any {
    const errorCodeTexts: any = {
      'permission-denied': {
        header: 'ERROR',
        title: 'PERMISSION_DENIED',
      },
      '': {
        header: 'ERROR',
        title: '',
      },
    };
    return errorCodeTexts[errorCode] || null;
  }

  async presentLoading(message: string = ''): Promise<void> {
    await this.dismissLoading();
    return new Promise(async resolve => {
      this.loading = await this.loadingController.create({
        backdropDismiss: false,
        spinner: 'crescent',
      });
      if (message) {
        this.loading.message = message;
      }
      await this.loading.present();
      resolve();
    });
  }
  dismissLoading(): Promise<void> {
    return new Promise(async resolve => {
      if (this.loading) {
        await this.loading.dismiss();
      }
      resolve();
    });
  }

  formatTextForTranslate(text: string) {
    const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
    let x = text.match(wordRegex)!.join('_').toUpperCase();
    return x;
  }

  async defaultRedirect(customToastText?: string, route?: string, silent?: boolean) {
    const text = customToastText;
    if (!silent) {
      this.showToast(text ? text : this.tp.transform('INVALID_ROUTE'), 2000);
    }
    this.router.navigate([route ? route : '/']);
    this.addLogItem('REDIRECTED', {
      from: this.router.url,
      to: route ? route : '/',
      toastPresented: silent ? false : true,
      toastText: text ? text : 'INVALID_ROUTE',
    });
  }

  async hashString(inputString: string) {
    // Konvertáljuk a bemeneti sztringet UTF-8 formátumú ArrayBuffer-ré
    const encoder = new TextEncoder();
    const data = encoder.encode(inputString);

    // Kiszámoljuk a hash-t
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Konvertáljuk a hash-t hexadecimális sztringgé
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  presentConfirmAlert(header: string, message?: string, subHeader?: string, buttons?: { okButton?: boolean; okButtonText?: string; cancelButton?: boolean; cancelButtonText?: string }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let alert: any;

      if (buttons?.okButton && buttons?.cancelButton) {
        alert = await this.alertController.create({
          header: header,
          message: message,
          subHeader: subHeader,
          mode: 'ios',
          cssClass: 'alert-center',
          buttons: [
            {
              text: buttons.cancelButtonText ? buttons.cancelButtonText : 'Cancel',
              cssClass: 'secondary',
              handler: () => {
                reject();
              },
            },
            {
              text: buttons.okButtonText ? buttons.okButtonText : 'OK',
              cssClass: 'danger',
              handler: () => {
                resolve();
              },
            },
          ],
        });
      } else if (buttons?.okButton && !buttons.cancelButton) {
        alert = await this.alertController.create({
          header: header,
          message: message,
          subHeader: subHeader,
          buttons: [
            {
              text: buttons.okButtonText ? buttons.okButtonText : 'OK',
              cssClass: 'dark',
              handler: () => {
                resolve();
              },
            },
          ],
        });
      } else if (!(buttons?.okButton && buttons.cancelButton)) {
        alert = await this.alertController.create({
          header: header,
          message: message,
          subHeader: subHeader,
          backdropDismiss: true,
        });
      }
      await alert.present();
    });
  }

  private loadLog() {
    return this.storage.get('log-array');
  }

  private storeLog() {
    return this.storage.set('log-array', this.offlineLog);
  }
}
